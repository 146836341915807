import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router'
import { getToken } from '@/utils/auth'
import NProgress from 'nprogress'

Vue.use(ElementUI);
Vue.config.productionTip = false

const whiteList = ['/']

router.beforeEach((to, from, next) => {
    NProgress.start()
    if (to.meta.title) {
        //判断是否有标题
        document.title = to.meta.title
    }
    if (getToken()) {
        /* has token*/
        if (to.path === '/') {
            next({ path: '/index' })
        } else {
            next()
        }
    } else {
        if (whiteList.indexOf(to.path) !== -1) {
            // 在免登录白名单，直接进入
            next()
        } else {
            next({ path: '/' })
        }
    }
    NProgress.done()
})

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'

export function getToken() {
    return Cookies.get(TokenKey)
}

export function setToken(token) {
    var domain = "." + document.domain.split('.').slice(-2).join('.')
    return Cookies.set(TokenKey, token, { domain: domain })
}

export function removeToken() {
    return Cookies.remove(TokenKey)
}